.summary-card {
    border: 1px solid #ccc;
    padding: 16px;
    border-radius: 40px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    margin: 16px;
    flex: 1 1 auto;
    min-width: 40ch;
    height: 40ch;
  }
  
  .summary-card h2 {
    margin-bottom: 16px;
    font-size: 30px;
    color: #6e8efb;
    text-align: center;
  }
  
  .summary-card .summary-money-stats {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  
  .summary-card .money-stats {
    flex: 1 1 auto;
    text-align: right;
    border-bottom: 1px solid black;
    margin-right: 9%;
    margin-top: 20px;
  }

  .summary-card .summary-other-stats {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 40px;
  }
  .summary-card .other-stats {
    flex: 1 1 auto;
    text-align: right;
    margin-right: 9%;
  }
  
  .summary-card .other-stats-titles {
    flex: 1 1 auto;
    margin-left: 9%;
  }
  
  .summary-card .money-stats-titles {
    flex: 1 1 auto;
    margin-left: 9%;
    border-bottom: 1px solid black;
    margin-top: 20px;
  }

  .summary-card .total-money-stat {
    flex: 1 1 auto;
    text-align: right;
    margin-right: 9%;
    margin-top: 20px;
  }

  .summary-card .total-money-stat-title {
    flex: 1 1 auto;
    margin-left: 9%;
    margin-top: 20px;
  }
  
  .money-stats div,
  .money-stats-titles div,
  .other-stats div,
  .other-stats-titles div,
  .total-money-stat div,
  .total-money-stat-title div {
    margin-bottom: 8px;
  }

  .other-stats div,
  .other-stats-titles div {
    margin-bottom: 10px;
  }
  
  .summary-card .highlight {
    font-weight: bold;
    color: #6e8efb;
  }
  
  .summary-card .negative-highlight {
    font-weight: bold;
    color: rgb(255, 127, 127);
  }

  .summary-card .bold {
    font-weight: bold;
    font-size: 18px;
  }