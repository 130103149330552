.forgot-password-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #6e8efb, #a777e3);
    font-family: 'Arial', sans-serif;
    padding: 20px;
    box-sizing: border-box;
}

.forgot-password-form-container {
    background: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    text-align: center;
    animation: fadeIn 0.6s ease-in-out;
}

.forgot-password-form-container h1 {
    margin-bottom: 20px;
    color: #333;
    font-size: 24px;
}

.forgot-password-form-container form {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.forgot-password-form-container input {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s;
}

.forgot-password-form-container input:focus {
    border-color: #6e8efb;
    outline: none;
}

.forgot-password-form-container .message, .forgot-password-form-container .error-message {
    margin-top: 10px;
    font-size: 14px;
}

.forgot-password-form-container .error-message {
    color: red;
}

.back-link {
    color: #6e8efb;
    background: none;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    font-size: 14px;
    display: inline;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.95);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}