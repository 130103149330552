
body {
    background-color: #f4f4f9;
    font-family: "Arial", sans-serif;
    margin: 0;
    padding: 0;
}
  
.header-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px;
  background-color: #6e8efb;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 10;
  color: white;
}

.header-bar h1 {
  margin: 0;
  font-size: 24px;
  color: white;
}

.left-section, .right-section {
  display: flex;
  align-items: center;
}

.menu-button, .refresh-button, .more-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  margin-right: 16px;
  color: white;
}

.menu-button:hover, .refresh-button:hover, .more-button:hover {
  color: #c0c0c0;
}
.overview-cards {
  display: flex;
  flex-wrap: wrap;
  row-gap: 0px;
  column-gap: 20px;
  margin-left: 13vw;
  margin-right: 13vw;
}

.card {
  flex: 1 1 calc(50% - 20px);
  box-sizing: border-box;
  min-width: calc(50% - 20px);
  max-width: calc(50% - 20px);
}

@media (max-width: 1200px) {
  .card {
    flex: 1 1 100%;
    min-width: 100%;
    max-width: 100%;
  }
}