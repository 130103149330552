.header-menu {
    position: relative;
    display: inline-block;
}

.menu-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    border-radius: 50%;
    transition: background-color 0.3s;
}
.dropdown-menu {
    position: absolute;
    right: 0;
    top: 110%;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 1000;
    overflow: hidden;
    min-width: 150px;
}

.dropdown-item {
    padding: 12px 16px;
    cursor: pointer;
    border: none;
    background: none;
    width: 100%;
    text-align: left;
    color: #333;
    font-size: 16px;
    transition: background-color 0.3s;
}

.dropdown-item:hover {
    background-color: #f0f0f0;
}

.dropdown-item:active {
    background-color: #e0e0e0;
}