.apps-card {
  border: 1px solid #ccc;
  padding: 16px;
  border-radius: 40px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin: 16px;
  flex: 1 1 auto;
  min-width: 41ch;
  height: 39ch;
  position: relative;
  overflow: hidden;
}

.apps-card h2 {
  margin-bottom: 16px;
  font-size: 30px;
  color: #6e8efb;
  text-align: center;
}

.carousel-wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-container {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
}

.app-stat {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 100%;
}

.app-stat-titles {
  margin-bottom: 10px;
  text-align: center;
}

.app-name {
  font-weight: bold;
  font-size: 18px;
}

.last-shift {
  font-size: 14px;
}

.app-stat-details {
  width: 80%;
  padding: 0 20px;
}

.app-stat-row {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
}

.app-stat-money {
  font-weight: bold;
  color: #6e8efb;
}

.sub-text {
  font-size: 12px;
}

.app-stat-number {
  font-weight: bold;
}

.carousel-control {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 15%;
  background: rgba(0, 0, 0, 0);
  cursor: pointer;
  transition: background 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-control:hover {
  background: rgba(0, 0, 0, 0.2);
  opacity: 0.5;
}

.carousel-control.left {
  left: 0;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
}

.carousel-control.right {
  right: 0;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
}

.carousel-control:hover:before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
}

.carousel-control.left:hover:before {
  border-width: 10px 15px 10px 0;
  border-color: transparent white transparent transparent;
}

.carousel-control.right::before {
  border-width: 10px 0 10px 15px;
  border-color: transparent transparent transparent white;
}

.carousel-dots {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.dot {
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.dot.active {
  background-color: #6e8efb;
}