body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: 'Arial', sans-serif;
}

.auth-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #6e8efb, #a777e3);
}

.auth-form-container {
    background: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    text-align: center;
    animation: fadeIn 0.6s ease-in-out;
}

.auth-form-container h1 {
    margin-bottom: 20px;
    color: #333;
    font-size: 24px;
}

.auth-form-container form {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.auth-form-container input {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s;
}

.auth-form-container input:focus {
    border-color: #6e8efb;
    outline: none;
}

.submit-button, .google-button {
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #6e8efb;
    color: white;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 10px;
    transition: background-color 0.3s, transform 0.2s;
}

.submit-button:hover, .google-button:hover {
    background-color: #576cfa;
    transform: translateY(-2px);
}

.social-login button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: none;
    border-radius: 5px;
    background-color: white;
    color: #6e8efb;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.social-login button:hover {
    background-color: #f0f0f0;
    transform: translateY(-2px);
}

.register-link, .forgot-password-link {
    color: #6e8efb;
    background: none;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    margin-top: 10px;
    font-size: 14px;
    display: inline;
    padding: 10px;
}

.register-link:hover, .forgot-password-link:hover {
    color: #576cfa;
    text-decoration: underline;
}

.register-link:focus, .forgot-password-link:focus {
    outline: none;
    color: #576cfa;
    text-decoration: underline;
}

.register-link:active, .forgot-password-link:active {
    color: #6e8efb;
    text-decoration: underline;
}

.google-logo {
    height: 24px;
    width: 24px;
    margin-right: 8px;
}

.auth-error-message {
    color: red;
    margin-top: 10px;
    font-size: 14px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.95);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

@media (max-width: 480px) {
    .auth-form-container {
        padding: 1rem;
    }

    .auth-form-container h1 {
        font-size: 20px;
    }
}
