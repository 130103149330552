.form-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

.form-container {
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0,1);
    width: 90%;
    max-width: 600px;
    max-height: 80%;
    margin: 20px auto;
    font-family: "Arial", sans-serif;
    animation: fadeIn 0.6s ease-in-out;
    position: relative;
    display: flex;
    flex-direction: column;
}

.form-header {
    position: sticky;
    top: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    left: 0;
    right: 0;
    background: white;
    padding: 10px 20px;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
}

.form-header .close-form-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #333;
}

.form-header .close-form-button:hover {
    color: #ff0000;
}

.form-header h2 {
    margin: 0;
    color: #333;
    font-size: 24px;
}

.form-container form {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.form-container form label {
    display: block;
    margin-bottom: 10px;
}

.form-container form label.checkbox-label {
    display: flex;
    align-items: left;
    justify-content: left;
    margin-bottom: 10px;
}

.form-container form label.checkbox-label span {
    white-space: nowrap;
}

.form-container form label.checkbox-label input[type="checkbox"] {
    margin-left: -1px;
    margin-right: 10px;
}

.checkboxes {
    margin-bottom: 15px;
}

.form-container input[type="text"],
.form-container input[type="number"],
.form-container input[type="datetime-local"],
.form-container select,
.form-container textarea {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    width: 95%;
    transition: border-color 0.3s;
}

.form-container input:focus,
.form-container select:focus,
.form-container textarea:focus {
    border-color: #6e8efb;
    outline: none;
}

.form-container .error-message {
    color: red;
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
}

.step-container {
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 20px;
}

.step-container h2 {
    margin-bottom: 20px;
}

.step-container .form-step {
    display: none;
    flex-direction: column;
    align-items: left;
}

.step-container .form-step.active {
    display: flex;
}

.step-container .buttons {
    margin-top: 20px;
    display: flex;
    justify-content: right;
}

.step-container .buttons button {
    margin: 5px;
    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;
    border: none;
    border-radius: 5px;
    background-color: #6e8efb;
    color: white;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
    transition: background-color 0.3s, transform 0.2s;
}

.step-container .buttons button:hover {
    background-color: #576cfa;
    transform: translateY(-2px);
}
.step-container .hours-and-minutes-entry {
    display: flex;
    justify-content: left;
    gap: 10px;
    width: 250px
}

.italicized-text {
    font-style: italic;
    font-size: 14px;
}

.bolded-text {
    font-weight: 700;
    font-size: 16px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.95);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}