.fab-container {
    position: fixed;
    bottom: 16px;
    right: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.fab-button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 50%;
    width: 56px;
    height: 56px;
    font-size: 24px;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.fab-button:hover {
    background: #0056b3;
  }

.fab-menu {
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.fab-menu-item {
    background-color: white;
    color: #007bff;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    margin-bottom: 8px;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s;
}

.fab-menu-item:hover {
    transform: translate(-8px);
}