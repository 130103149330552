.recent-activity-card {
  border: 1px solid #ccc;
  padding: 16px;
  border-radius: 40px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin: 16px;
  flex: 1 1 auto;
  min-width: 40ch;
  height: 40ch;
}

.recent-activity-card h2 {
  margin-bottom: 16px;
  font-size: 30px;
  color: #6e8efb;
  text-align: center;
}

.activity-sections {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.activity-section {
  margin-bottom: 10px;
  flex: 1 1 auto;
  margin-top: 3ch;
}

.activity-section h3 {
  margin-left: 7%;
  margin-bottom: 20px;
  font-size: 22px;
  text-align: center;
}

.activity-stats {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stat-item {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-bottom: 5px;
}

.stat-item:last-child {
  border-top: 1px solid black;
  padding-top: 10px;
}

.stat-title {
  flex: 1;
  text-align: left;
}

.stat-value {
  flex: 1;
  text-align: right;
}

.total-stat {
  font-weight: bold;
  font-size: 18px;
}

.highlight {
  font-weight: bold;
  color: #6e8efb;
}

.negative-highlight {
  font-weight: bold;
  color: rgb(255, 127, 127);
}