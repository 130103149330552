.earnings-per-app-card {
    border: 1px solid #ccc;
    padding: 16px;
    border-radius: 40px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    margin: 16px;
    flex: 1 1 auto;
    min-width: 40ch;
    height: 39ch;
  }

  .earnings-per-app-card h2 {
    margin-bottom: 16px;
    font-size: 30px;
    color: #6e8efb;
    text-align: center;
  }